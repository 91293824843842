<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent"></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
// import supplierWorkOrderModule from '@/store/modules/workOrder_supplier'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 底部组件
    IndexTable: () => import('@/components/setting/message/IndexTable')

    // 右侧组件

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setBottomComponent('IndexTable')
    }
  }
  // destroyed () {
  //   this.$store.unregisterModule('supplierWorkOrder')
  // }
}
</script>
